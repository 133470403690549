import { UPDATE_CLIENT } from "../actions/types"; 

const initialState = {
  clientId: null,
};

const client = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_CLIENT:
        return { ...payload };

    default:
      return state;
  }
};

export default client;
