import { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Actions
import { logout, validateUser } from './actions/auth';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Utils
import setAuthToken from './utils/setAuthToken';

// Components
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Loading from './components/Loading/ScreenLoading';
import Alert from './components/Alert/Alert';

// Access List
import pageList from './routes';

// Pages - Public
import NotFound from './pages/NotFound';
import Forbidden from './pages/Forbidden';
import DataBaseConnectTest from './components/DatabaseConnectTest/DataBaseConnectTest';

const App = () => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(validateUser());
    } else {
      const acceptableUrls = ['/', '/forgot-password', '/forbidden', '/not-found', '/reset-password'];
      // If not on a public page, logout
      if (!acceptableUrls.includes(window.location.pathname)) {
        store.dispatch(logout());
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Alert />
          <Routes>
            {pageList.map((page, index) =>
              page.type === 'public' ? (
                <Route
                  key={index}
                  path={page.path}
                  element={
                    <Suspense fallback={<Loading />}>
                      <page.component />
                    </Suspense>
                  }
                />
              ) : (
                <Route
                  key={index}
                  path={page.path}
                  element={
                    <Suspense fallback={<Loading />}>
                      <DataBaseConnectTest>
                        <PrivateRoute component={page.component} access={page.access as string} />
                      </DataBaseConnectTest>
                    </Suspense>
                  }
                />
              )
            )}
            <Route path='*' element={<NotFound />} />
            <Route path='/forbidden' element={<Forbidden />} />
          </Routes>
        </>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
