import React from "react";
import { Link } from "react-router-dom";

// Components
import Icon from "../Icons/Icon";

interface ButtonProps {
  type: "button" | "submit" | "link" | "iconOnly" | "externalLink";
  style?: "default" | "text";
  children: React.ReactNode;
  to?: string;
  colour?: "primary" | "red" | "default";
  scale: "full-width" | "default";
  className?: string;
  startIcon?: string;
  endIcon?: string;
  loading: boolean;
  onClick?: (e: any) => void;
  iconDescription?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const buttonClassNames = [
    "arcus-button",
    props.style === "text" ? "arcus-button__text" : "",
    props.colour ? `arcus-button__colour--${props.colour}` : "",
    `arcus-button__${props.scale}`,
    props.type === "iconOnly" ? "arcus-button__icon-only" : "",
    props.className || "",
  ]
    .filter(Boolean)
    .join(" ");

  switch (props.type) {
    case "link":
      return (
        <Link to={props.to || ""} className={buttonClassNames}>
          {props.startIcon && (
            <div className="arcus-button__icon--start">
              <Icon iconType={props.startIcon} />
            </div>
          )}
          {props.children}
          {props.endIcon && (
            <div className="arcus-button__icon--end">
              <Icon iconType={props.endIcon} />
            </div>
          )}
        </Link>
      );

    case "externalLink":
      return (
        <a href={props.to || ""} className={`arcus-button__external-link`} target="_blank" rel="noreferrer">
          {props.children}
          {props.endIcon && <Icon iconType={props.endIcon} />}
        </a>
      );

    default:
      return (
        <button type={props.type === "iconOnly" ? "button" : props.type} className={buttonClassNames} onClick={props.onClick}>
          {props.loading && (
            <div className="arcus-button__loading">
              <Icon iconType="loading" />
            </div>
          )}
          <div className={props.loading ? "hide" : "content-flex"}>
            {props.startIcon && <div className="arcus-button__icon--start"><Icon iconType={props.startIcon} /></div>}
            {props.children}
            {props.endIcon && <div className="arcus-button__icon--end"><Icon iconType={props.endIcon} /></div>}
          </div>
        </button>
      );
  }
};

export default Button;
