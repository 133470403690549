import React from "react";
import { connect } from "react-redux";

// Actions
import { logout } from "../actions/auth";

// Components
import Button from "../components/Button/Button";

// Types
type ForbiddenProps = {
  logout: () => void;
};

const Forbidden: React.FC<ForbiddenProps> = ({ logout }) => {
  return (
    <div className="container arcus-forbidden-notfound">
      <h1>Forbidden</h1>
      <p>You do not have access to this page.</p>
      <center className="content-center content-flex-row">
        <Button
          {...{
            type: "link",
            scale: "default",
            to: "/",
            loading: false,
          }}
        >
          Go to Home
        </Button>
        <Button
          {...{
            type: "button",
            scale: "default",
            loading: false,
            onClick: () => logout(),
            colour: "primary",
            className: "margin-left-small",
          }}
        >
          Logout
        </Button>
      </center>
    </div>
  );
};

export default connect(null, { logout })(Forbidden);
