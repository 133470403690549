import { lazy } from 'react';

import Logon from './pages/Logon';

const pageList = [
  // ---- PUBLIC PAGES ---- //
  { type: 'public', path: '/', component: Logon },
  { type: 'public', path: '/forgot-password', component: lazy(() => import('./pages/ForgotResetPassword/ForgotPassword')) },
  { type: 'public', path: '/reset-password', component: lazy(() => import('./pages/ForgotResetPassword/ResetPassword')) },

  // ---- PRIVATE PAGES ---- //
  // ---- Supporting Pages ---- //
  { type: 'private', path: '/profile', component: lazy(() => import('./pages/Profile/Profile')), access: 'no-access-required' },
  { type: 'private', path: '/feedback', component: lazy(() => import('./pages/Feedback')), access: 'no-access-required' },

  // ---- Administration ---- //
  { type: 'private', path: '/administration', component: lazy(() => import('./pages/Administration/Administration')), access: 'no-access-required' },

  // Administration - Clients
  { type: 'private', path: '/administration/clients/join', component: lazy(() => import('./pages/Administration/Clients/Join')), access: 'Administration:Clients:OnboardNewClient' },
  { type: 'private', path: '/administration/clients/:clientId', component: lazy(() => import('./pages/Administration/Clients/View')), access: 'Administration:Clients:ViewClient' },
  { type: 'private', path: '/administration/clients/:clientId/edit', component: lazy(() => import('./pages/Administration/Clients/Edit')), access: 'Administration:Clients:UpdateClient' },
  {
    type: 'private',
    path: '/administration/clients/:clientId/configuration',
    component: lazy(() => import('./pages/Administration/Clients/ClientConfiguration')),
    access: 'Administration:Clients:ClientConfiguration'
  },
  { type: 'private', path: '/administration/clients/:clientId/Users/create', component: lazy(() => import('./pages/Administration/Users/Create')), access: 'Administration:Clients:AddClientUser' },
  { type: 'private', path: '/administration/clients/:clientId/users/:userId', component: lazy(() => import('./pages/Administration/Users/View')), access: 'Administration:Clients:ViewClientUser' },
  { type: 'private', path: '/administration/clients/:clientId/users/:userId/edit', component: lazy(() => import('./pages/Administration/Users/Edit')), access: 'Administration:Users:UpdateUser' },
  { type: 'private', path: '/administration/clients/:clientId/roles/:roleId', component: lazy(() => import('./pages/Administration/Roles/View')), access: 'Administration:Roles:ViewRole' },
  { type: 'private', path: '/administration/clients/:clientId/roles/:roleId/edit', component: lazy(() => import('./pages/Administration/Roles/Edit')), access: 'Administration:Roles:UpdateRole' },

  // Administration - Roles
  { type: 'private', path: '/administration/roles/create', component: lazy(() => import('./pages/Administration/Roles/Create')), access: 'Administration:Roles:CreateRole' },
  { type: 'private', path: '/administration/roles/:roleId', component: lazy(() => import('./pages/Administration/Roles/View')), access: 'Administration:Roles:ViewRole' },
  { type: 'private', path: '/administration/roles/:roleId/edit', component: lazy(() => import('./pages/Administration/Roles/Edit')), access: 'Administration:Roles:UpdateRole' },

  // Administration - Users
  { type: 'private', path: '/administration/users/create', component: lazy(() => import('./pages/Administration/Users/Create')), access: 'Administration:Users:Createuser' },
  { type: 'private', path: '/administration/users/:userId', component: lazy(() => import('./pages/Administration/Users/View')), access: 'Administration:Users:ViewUser' },
  { type: 'private', path: '/administration/users/:userId/edit', component: lazy(() => import('./pages/Administration/Users/Edit')), access: 'Administration:Users:UpdateUser' },

  // Administration - Application Configuration
  { type: 'private', path: '/administration/application-config', component: lazy(() => import('./pages/Administration/AppConfig/Index')), access: 'Administration:System:ViewConfig' },

  // ---- Dashboards ---- //
  // Detailed
  { type: 'private', path: '/dashboard/:clientId/groups', component: lazy(() => import('./pages/Dashboards/Detailed/DetailedDashboard')), access: 'Dashboard:Detailed:ViewDashboard' },
  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/stores',
    component: lazy(() => import('./pages/Dashboards/Detailed/Client-Level/Index')),
    access: 'Dashboard:Detailed:ViewClientLevelDashboard'
  },
  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/stores/:storeId',
    component: lazy(() => import('./pages/Dashboards/Detailed/Store-Level/Index')),
    access: 'Dashboard:Detailed:ViewStoreLevelDashboard'
  },
  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/stores/:storeId/asset/:assetId',
    component: lazy(() => import('./pages/Dashboards/Detailed/Asset-Level/Index')),
    access: 'Dashboard:Detailed:ViewAssetLevelDash'
  },
  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/stores/:storeId/asset/:assetId/healthBreakdown',
    component: lazy(() => import('./pages/Dashboards/Detailed/Asset-Level/HealthBreakdown')),
    access: 'Dashboard:Detailed:HealthBreakdown'
  },

  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/stores/:storeId/asset/:assetId/workorders',
    component: lazy(() => import('./pages/Dashboards/Detailed/Asset-Level/WorkOrders')),
    access: 'Dashboard:Detailed:ViewAssetWorkOrders'
  },

  // Executive
  { type: 'private', path: '/dashboard/:clientId/executive', component: lazy(() => import('./pages/Dashboards/Executive/ExecutiveDashboard')), access: 'Dashboard:Executive:ViewDashboard' },

  //Capital
  {
    type: 'private',
    path: '/dashboard/:clientId/group/:groupId/capitalforecasting',
    component: lazy(() => import('./pages/CapitalForecasting/CapitalForecasting')),
    access: 'Dashboard:CapitalForecast:ViewDashboard'
  }
];

export default pageList;
