import { ERROR, REMOVE_ERROR } from "../actions/types";

const initialState = {
  message: null,
};

const error = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ERROR:
      return { ...state, ...payload };

    case REMOVE_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default error;
