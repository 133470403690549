import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Icon from "../Icons/Icon";

const propTypes = {
  alert: PropTypes.shape({
    heading: PropTypes.string || null,
    msg: PropTypes.string || null,
    type: PropTypes.oneOf(["success", "danger"]) || null,
    className: PropTypes.string || null,
  }).isRequired,
};

type AlertProps = PropTypes.InferProps<typeof propTypes>;

const Alert: React.FC<AlertProps> = ({ alert }) => {
  if (!alert || !alert.heading) return null;

  return (
    <>
      <div className={`arcus-alert ${alert.className} ${alert.type === "success" ? "arcus-alert__success" : "arcus-alert__warning"}`}>
        <div className="arcus-alert__heading">
          <Icon iconType={alert.type === "success" ? "check" : "warning"} />
          <h4>{alert.heading}</h4>
        </div>
        <p>{alert.msg}</p>
      </div>
    </>
  );
};

Alert.propTypes = propTypes;

const mapStateToProps = (state: any) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(Alert);
