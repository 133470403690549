import { AxiosError } from 'axios';

import store from '../store';

// Actions
import { setAlert } from '../actions/alert';

const handleAxoisError = (err: AxiosError) => {
  if (err.response) {
    // Request made and server responded
    if (process.env.NODE_ENV === 'development') console.log(err.response.data);
    store.dispatch(setAlert('Something went wrong', (err.response.data as any).errors[0].msg, 'danger'));

    if ((err.response.data as any).errors[0].msg === 'Client not active') {
      const logonLocation = store.getState().auth.logonLocation;
      if (logonLocation) {
        window.location.href = `/${logonLocation}`;
      }
    }
  } else if (err.request) {
    // The request was made but no response was received
    if (process.env.NODE_ENV === 'development') console.log(err.request);
    store.dispatch(setAlert('Something went wrong', 'No response from server', 'danger'));
  } else {
    // Something happened in setting up the request that triggered an Error
    if (process.env.NODE_ENV === 'development') console.log('Error', err.message);
    store.dispatch(setAlert('Something went wrong', err.message, 'danger'));
  }
};

export const processError = (error: any) => {
  if (error instanceof AxiosError) {
    handleAxoisError(error);
    return;
  }

  if (error instanceof Error) {
    store.dispatch(setAlert('Something went wrong', error.message, 'danger'));
    return;
  }

  if (typeof error === 'string') {
    store.dispatch(setAlert('Something went wrong', error, 'danger'));
    return;
  }

  store.dispatch(setAlert('Something went wrong', 'Unknown error', 'danger'));
};
