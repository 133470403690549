import React from "react";

// Svg
import {ReactComponent as LoadingSvg} from "../../assets/iconSvgs/spinner.svg";

// Interface
interface ScreenLoadingProps {
  fadeOut?: boolean;
}


const ScreenLoading = (props: ScreenLoadingProps) => {
  return <div className={`arcus-loading screen-loading ${props.fadeOut && "loading-fade-out"}`}>
    <LoadingSvg />
  </div>;
};

export default ScreenLoading;
