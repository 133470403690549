import { REMOVE_ALERT, LOGIN_SUCCESS, USER_VALIDATED, AUTH_ERROR, LOGOUT, UPDATE_USER, UPDATE_CLIENT } from './types';
import axios from 'axios';

// Utils
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import { processError } from '../utils/errorHandling';

// This function will validate the user when they refresh the web page.
export const validateUser = () => async (dispatch: any) => {
  try {
    const resp = await axios.get('/api/auth');

    const data = resp.data.data;

    if (!data.access.isAdmin) {
      localStorage.setItem('client', data.clientInfo.clientId);
    }

    dispatch({ type: UPDATE_USER, payload: data.user });
    dispatch({ type: UPDATE_CLIENT, payload: data.clientInfo });
    dispatch({ type: USER_VALIDATED, payload: { access: data.access, logonLocation: data.logonLocation } });
  } catch (err) {
    processError(err);
    dispatch(logout());
    dispatch({ type: AUTH_ERROR });
  }
};

// This will log the user in and set the token in localStorage
export const login = (emailAddress: string, password: string) => async (dispatch: any) => {
  try {
    // Remove all alerts & localStorage data
    dispatch({ type: REMOVE_ALERT });
    localStorage.clear();

    // Check login credentials
    const resp = await axios.post('/api/auth/login', { emailAddress, password });

    const { user, token, access, logonLocation, pageView, clientInfo } = resp.data.data;

    if (token) {
      dispatch({ type: UPDATE_USER, payload: user });
      dispatch({ type: UPDATE_CLIENT, payload: clientInfo });
      dispatch({ type: LOGIN_SUCCESS, payload: { token: token, access: access, logonLocation: logonLocation, pageView: pageView } });

      setAuthToken(token);

    } else {
      dispatch(setAlert('Login Error', resp.data.data, 'danger'));
      dispatch({ type: AUTH_ERROR });
    }
  } catch (err: any) {
    if (err.response.status === 400 && err.response.data.errors[0].msg === 'Please press forgot password to set your password') {
      // navigate to forgot password page
      window.location.href = '/forgot-password?new=true';
    }
    processError(err);
    dispatch({ type: AUTH_ERROR });
  }
};

// This will log out the user
export const logout = () => (dispatch: any) => {
  window.localStorage.clear();
  setAuthToken();
  dispatch({ type: LOGOUT });
  // Redirect to login page
  window.location.href = '/';
};
