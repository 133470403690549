import { UPDATE_USER } from "../actions/types";

const initialState = {
  userId: null,
  name: null,
  emailAddress: null,
};

const user = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER:
        return { ...state, ...payload };

    default:
        return state;

  }

};

export default user;
