import axios from "axios";

// * Set Auth Token
// ? This function is called to set the auth token in the header and remove if there is no token
const setAuthToken = (token?: string) => {
  if (token) {
    axios.defaults.headers.common["x-auth-token"] = token;
    localStorage.setItem("token", token);
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
    localStorage.removeItem("token");
  }
};

export default setAuthToken;
