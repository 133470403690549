import React, { useState } from "react";
import Icon from "../Icons/Icon";

// Interface
interface InputProps {
  label?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type: string;
  marginBottom?: boolean;
  paddingStart?: boolean;
  startIcon?: string;
  endIcon?: string;
  loseIconJustify?: boolean;
  underneathItem?: React.ReactNode;
  additionlInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  style?: "short" | "medium";
}

const Input: React.FC<InputProps> = (props) => {
  // States
  const [toggleViewPassword, setToggleViewPassword] = useState("eye-hide");
  const [inputType, setInputType] = useState(props.type);

  // Functions
  const toggleViewPasswordFunc = () => {
    setToggleViewPassword(toggleViewPassword === "eye-hide" ? "eye-show" : "eye-hide");
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  // Render
  return (
    <div className={`arcus-input ${props.marginBottom === false ? "" : "margin-bottom"} ${props.style && `arcus-input--${props.style}`}`}>
      {props.label && (
        <label className="arcus-input__label" htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <div className="arcus-input__inner-wrapper">
        {props.startIcon && (
              <div className={`arcus-input__left-icon ${props.loseIconJustify ? "content-start" : ""}`}>
              <Icon iconType={props.startIcon} />
            </div>
        )}
      <input
          type={inputType}
          name={props.name}
          id={props.name}
          value={props.value}
          onChange={props.onChange}
          className={`arcus-input__input ${props.paddingStart ? "padding-left-medium" : ""}`} 
          {...props.additionlInputProps}
        />
        <div className="arcus-input__right-icon">
          {props.type === "password" && (
            <button type="button" onClick={toggleViewPasswordFunc}>
              <Icon iconType={toggleViewPassword} />
            </button>
          )}
          {props.endIcon && <Icon iconType={props.endIcon} />}
        </div>
      </div>
      {props.underneathItem}
    </div>
  );
};

export default Input;
