import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

// Actions
import { setError } from '../../actions/error';
import { setDatabaseConnection } from '../../actions/system';

// Svg
import { ReactComponent as LoadingSvg } from '../../assets/iconSvgs/spinner.svg';

// Types
type DataBaseConnectTestProps = {
  children?: React.ReactNode;

  //   Redux
  setError: (msg: string) => void;
  setDatabaseConnection: (connected: boolean) => void;
  databaseConnected: boolean;
  client: any;
  isAdmin: boolean;
  isDeveloper: boolean;
};

const DataBaseConnectTest: React.FC<DataBaseConnectTestProps> = ({
  setError,
  setDatabaseConnection,
  databaseConnected,
  client,
  isAdmin,
  isDeveloper,
  children
}) => {
  const [connectionLoading, setConnectionLoading] = useState(true);
  console.log(client)

  const testConnection = useCallback(async () => {
    console.log(client.isServerless)
    if (databaseConnected || !client.isServerless) {
      setConnectionLoading(false);
      return;
    }

    try {
      if (Object.keys(client).length === 0 && (isAdmin || isDeveloper)) {
        setConnectionLoading(false);
        return;
      }

      if (client && client.clientId && !databaseConnected) {
        setConnectionLoading(true);

        let success = false;
        let attempts = 0;
        const maxAttempts = 12; // Limit to 12 attempts (1-minute total wait)

        while (!success && attempts < maxAttempts) {
          attempts++;
          console.log(client)
          if (client && client.clientId) {
            try {
              console.log(attempts);
              console.log("trying")
              await axios.get(`/api/startConnection/connect/${client.clientId}`);
              success = true;
              setDatabaseConnection(true);
            } catch (err) {
              console.error(err);
            }
          }

          if (!success) {
            await new Promise((resolve) => setTimeout(resolve, 5000)); // 5-second delay between attempts
          }
        }

        setConnectionLoading(false);

        if (!success) {
          setError('We encountered an issue connecting to the database. Please try again later.');
        }
      }
    } catch (err) {
      console.error(err);
      setConnectionLoading(false);
      setError('An unexpected error occurred. Please try again later.');
    }
  }, [client, databaseConnected, isAdmin, isDeveloper, setError, setDatabaseConnection]);

  useEffect(() => {
    if (Object.keys(client).length === 0 && (isAdmin || isDeveloper)) {
      setConnectionLoading(false);
      return;
    }

    testConnection();
  }, [client, isAdmin, isDeveloper, testConnection]);

  if (!connectionLoading) return <>{children}</>;

  return (
    <div className='arcus-start-connection-loading'>
      <div className={`arcus-loading item-loading`}>
        <LoadingSvg />
      </div>
      <h1>We Are Gathering Your Information</h1>
      <p>Please wait while our system gathers your information.</p>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  error: state.error,
  client: state.client,
  databaseConnected: state.system.databaseConnected,
  isAdmin: state.auth.access.isAdmin,
  isDeveloper: state.auth.access.isDeveloper
});

export default connect(mapStateToProps, { setError, setDatabaseConnection })(DataBaseConnectTest);
