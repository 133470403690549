import store from "../store";

export const hasPermission = (permission: string) => {
  // Get access
  const access = store.getState().auth.access;

  // Developer admin
  if (access.isDeveloper) return true;

  // Global Admin
  if (access.isAdmin) {
    const accessDeniedForAdmin = ["Administration:System:ViewSystemConfig", "Administration:Clients:ReleaseClient", "Administration:Users:DeleteUser"];

    if (accessDeniedForAdmin.includes(permission)) return false;

    return true;
  }

  // Client User

  if (access.roleData === undefined) return false;
  const roleAccess = access.roleData.access;

  const hasAccess = roleAccess.includes(permission);

  return hasAccess;
};
