import React, { useState } from "react";

// Icons
import { ReactComponent as Filter } from "../../assets/iconSvgs/filter.svg";
import { ReactComponent as Home } from "../../assets/iconSvgs/home.svg";
import { ReactComponent as Info } from "../../assets/iconSvgs/info.svg";
import { ReactComponent as More } from "../../assets/iconSvgs/more.svg";
import { ReactComponent as Notdef } from "../../assets/iconSvgs/notdef.svg";
import { ReactComponent as Warning } from "../../assets/iconSvgs/warning.svg";
import { ReactComponent as EyeShow } from "../../assets/iconSvgs/eye-show.svg";
import { ReactComponent as EyeHide } from "../../assets/iconSvgs/eye-hide.svg";
import { ReactComponent as Check } from "../../assets/iconSvgs/check.svg";
import { ReactComponent as Loading } from "../../assets/iconSvgs/spinner.svg";
import { ReactComponent as Logout } from "../../assets/iconSvgs/logout.svg";
import { ReactComponent as Profile } from "../../assets/iconSvgs/profile.svg";
import { ReactComponent as Settings } from "../../assets/iconSvgs/settings.svg";
import { ReactComponent as Support } from "../../assets/iconSvgs/support.svg";
import { ReactComponent as Feedback } from "../../assets/iconSvgs/feedback.svg";
import { ReactComponent as ChevronRight } from "../../assets/iconSvgs/chevron-right.svg";
import { ReactComponent as External } from "../../assets/iconSvgs/external.svg";
import { ReactComponent as Trial } from "../../assets/iconSvgs/trial.svg";
import { ReactComponent as Clock } from "../../assets/iconSvgs/clock.svg";
import { ReactComponent as ChevronDown } from "../../assets/iconSvgs/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/iconSvgs/chevron-up.svg";
import { ReactComponent as Edit } from "../../assets/iconSvgs/edit.svg";
import { ReactComponent as Delete } from "../../assets/iconSvgs/delete.svg";
import { ReactComponent as Add } from "../../assets/iconSvgs/add.svg";
import { ReactComponent as Terminal } from "../../assets/iconSvgs/terminal.svg";
import { ReactComponent as Close } from "../../assets/iconSvgs/close.svg";
import { ReactComponent as Excel } from "../../assets/iconSvgs/excel.svg";
import { ReactComponent as Email } from "../../assets/iconSvgs/email.svg";

// Icon props
interface IconProps {
  iconType?: string;
  description?: string;
  iconSize?: 12 | 16 | 20;
  colour?: "blue" | "grey" | "green" | "red" | "orange";
}

const Icon = ({ iconType = "Notedef", description, iconSize, colour }: IconProps): JSX.Element => {
  const [toggleDesc, setToggleDesc] = useState<boolean>(true);
  const icons = [
    { name: "filter", svg: <Filter /> },
    { name: "home", svg: <Home /> },
    { name: "info", svg: <Info /> },
    { name: "more", svg: <More /> },
    { name: "notdef", svg: <Notdef /> },
    { name: "warning", svg: <Warning /> },
    { name: "eye-show", svg: <EyeShow /> },
    { name: "eye-hide", svg: <EyeHide /> },
    { name: "check", svg: <Check /> },
    { name: "loading", svg: <Loading /> },
    { name: "logout", svg: <Logout /> },
    { name: "profile", svg: <Profile /> },
    { name: "settings", svg: <Settings /> },
    { name: "support", svg: <Support /> },
    { name: "feedback", svg: <Feedback /> },
    { name: "chevron-right", svg: <ChevronRight /> },
    { name: "external", svg: <External /> },
    { name: "trial", svg: <Trial /> },
    { name: "clock", svg: <Clock /> },
    { name: "chevron-down", svg: <ChevronDown /> },
    { name: "chevron-up", svg: <ChevronUp /> },
    { name: "edit", svg: <Edit /> },
    { name: "delete", svg: <Delete /> },
    { name: "add", svg: <Add /> },
    { name: "terminal", svg: <Terminal /> },
    { name: "close", svg: <Close /> },
    { name: "excel", svg: <Excel /> },
    { name: "email", svg: <Email /> },

  ];

  // filter out just the icon we want, if not found return the notdef icon

  const classNames = ["arcus-icon", description ? "arcus-icon--clickable" : "", colour ? `arcus-icon__colour--${colour}` : "", iconSize ? `arcus-icon__size--${iconSize}` : ""]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={classNames} onClick={() => setToggleDesc(!toggleDesc)}>
      {icons.filter((icon) => icon.name === iconType)[0]?.svg || <Notdef />}
      {description && <div className={`arcus-icon__description ${toggleDesc ? "arcus-icon__description--hide" : ""}`}>{description}</div>}
    </div>
  );
};

export default Icon;
