import { logout } from "./auth";
import { SET_ALERT, REMOVE_ALERT, FADE_ALERT } from "./types";

export interface AlertSetter {
  setAlert: (heading: string, msg: string, type: "success" | "danger", className?: string) => void;
}

export const setAlert = (heading: string, msg: string, type: "success" | "danger", className?: string) => (dispatch: any) => {
  dispatch({ type: SET_ALERT, payload: { heading, msg, type, className: `alert-fade-in ${className}` } });
  if(msg=== "No token, authorization denied") dispatch(logout());
  setTimeout(() => dispatch({ type: FADE_ALERT, payload: { className: `alert-fade-out ${className}` } }), 5000);
  setTimeout(() => dispatch({ type: REMOVE_ALERT }), 5500);
};
