import { SET_FILTERS, CLEAR_FILTERS } from "../actions/types";

const initialState = {

};

const filters = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTERS:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_FILTERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default filters;