import { combineReducers } from 'redux';

// Reducers

import alert from './alert';
import auth from './auth';
import client from './client';
import user from './user';
import system from './system';
import error from './error';
import filters from './filters';
import storeAsset from './storeAsset';

export default combineReducers({ alert, auth, client, user, system, error, filters, storeAsset });
