import { SET_SECURITY_LIST, DB_CONNECTION } from '../actions/types';

const initialState = {
  securityList: [],
  databaseConnected: false
};

const system = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SECURITY_LIST:
      return { ...state, ...payload };

    case DB_CONNECTION:
      return { ...state, databaseConnected: payload };

    default:
      return state;
  }
};

export default system;
