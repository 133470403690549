import { AUTH_ERROR, LOGIN_SUCCESS, LOGOUT, USER_VALIDATED, UPDATE_TOKEN } from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  logonLocation: null,
  access: {
    isDeveloper: false,
    clientAccess: [],
    isAdmin: false,
    roleData: null
  },
  pageView: null
};

const auth = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case USER_VALIDATED:
      return { ...state, isAuthenticated: true, loading: false, ...payload };

    case UPDATE_TOKEN:
      return { ...state, token: payload.token };

    case LOGIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true, loading: false };

    case AUTH_ERROR:
    case LOGOUT:
      return { ...state, token: null, isAuthenticated: false, loading: false, user: null };

    default:
      return state;
  }
};

export default auth;
