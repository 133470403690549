import { SET_STORE, SET_ASSET } from '../actions/types';

const initialState = {
  store: {
    storeName: null,
    storeId: null
  },
  asset: {
    assetName: null,
    assetId: null
  }
};

const storeAsset = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_STORE:
      return { ...state, store: payload };

    case SET_ASSET:
      return { ...state, asset: payload };

    default:
      return state;
  }
};

export default storeAsset;
