import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('arcus-body') as HTMLElement);

if (process.env.NODE_ENV === 'development') {
  root.render(<App />);
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
