import { REMOVE_ALERT, SET_ALERT, FADE_ALERT } from "../actions/types";

const initialState = {
  heading: null,
  type: null,
  msg: null,
  className: null,
};

const alert = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return { ...state, ...payload };

    case FADE_ALERT:
      return { ...state, ...payload };

    case REMOVE_ALERT:
      return initialState;

    default:
      return state;
  }
};

export default alert;
