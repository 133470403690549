import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

// Components
import Loading from '../Loading/ScreenLoading';
import { hasPermission } from '../../utils/hasPermission';

// Pages
import Error from '../../pages/Error';
import Forbidden from '../../pages/Forbidden';

interface PrivateRouteProps {
  component: React.FC;
  access: string;
  auth: {
    isAuthenticated: boolean;
    loading: boolean;
    token: string;
    user: any;
    access: {
      clientAccess: { [key: string]: boolean };
      groupAccess: { [key: string]: groupAccess };
      isAdmin: boolean;
      isDeveloper: boolean;
      roleData: {
        access: Array<string>;
      };
    };
  };
  error: {
    message: string;
  };
}

type groupAccess = {
  access: boolean;
  PKID: number;
  sortOrder: number;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, access = null, auth, error }) => {
  // Get clientId from path
  const { clientId: clientIdFromPath, groupId } = useParams();

  // Error
  if (error.message) return <Error />;

  // Show loading icon if auth is loading
  if (auth.loading && auth.token) return <Loading />;

  // Check if access is not required on this page
  if (access === 'no-access-required') return <Component />;

  // check if user is a developer admin
  if (auth.access.isDeveloper) return <Component />;
  // Check to see if access and auth.access are not null
  if (access !== null && auth.access !== null) {
    // Check to see if clientId is in the path

    if (clientIdFromPath) {
      // Check to see if the user has access to this client.
      const allowClientAccess = Object.keys(auth.access.clientAccess).includes(clientIdFromPath);

      if (!allowClientAccess) return <Forbidden />;

      // Check to seee if groupId is in the path
      if (groupId) {
        const allowGroupAccess = Object.values(auth.access.groupAccess)
          .map((group: groupAccess) => group.PKID)
          .includes(parseInt(groupId));

        if (!allowGroupAccess) return <Forbidden />;
      }
    }

    // Check to see if the user has access to this page

    if (hasPermission(access)) {
      return <Component />;
    } else {
      return <Forbidden />;
    }
  }

  if (auth.loading && auth.token) return <Loading />;
  if (auth.isAuthenticated) return <Component />;

  return <Navigate to='/' />;
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(PrivateRoute);
