import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

// Actions
import { AlertSetter, setAlert } from '../actions/alert';
import { removeError } from '../actions/error';

// Components
import Button from '../components/Button/Button';
import { processError } from '../utils/errorHandling';

// Types
type ErrorPropTypes = {
  message: string;
  setAlert: AlertSetter['setAlert'];
  removeError: () => void;
  logonLocation: string;
};

const Error: React.FC<ErrorPropTypes> = ({ message, setAlert, removeError, logonLocation }) => {
  const goBackFunction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    removeError();

    window.location.href = `/${logonLocation}`;
  };

  const sendAlertFunction = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      await axios.post('/api/feedback/serverError', { message });

      setAlert('Alert Sent', 'An Alert has been sent out to the DevOps team', 'success');
    } catch (err: any) {
      processError(err);
    }
  };

  return (
    <div className='arcus-error-page'>
      <div className='arcus-error-page__heading'>
        <h1>Server Error</h1>
        <p>Sorry, there seems to be a problem within our servers.</p>
      </div>
      <div className='arcus-error-page__error'>
        <h2>Details</h2>
        <hr />
        <div className='arcus-error-page__error--content'>
          <p>
            <b className='colour-primary'>Message:</b> {message}
          </p>

          <p>
            <b className='colour-primary'>Action:</b> An Engineer has been alerted and will fix this issue as fast as possible.
          </p>
        </div>
      </div>
      <div className='arcus-error-page__footer'>
        <Button
          {...{
            scale: 'default',
            loading: false,
            type: 'button',
            colour: 'primary',
            onClick: sendAlertFunction
          }}
        >
          Send Alert
        </Button>
        <Button
          {...{
            scale: 'default',
            loading: false,
            type: 'button',
            className: 'margin-left-small',
            onClick: goBackFunction
          }}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  message: state.error.message,
  logonLocation: state.auth.logonLocation
});

export default connect(mapStateToProps, { setAlert, removeError })(Error);
