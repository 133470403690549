import React from 'react'

// Components
import Button from '../components/Button/Button'

const NotFound = () => {
  return (
    <div className='container arcus-forbidden-notfound'>
        <h1>404 Not Found</h1>
        <p>Sorry, this page does not exist</p>
        <Button
        {...{
          type: "link",
          scale: "default",
          to: "/",
          loading: false,
        }}
      >
        Go to Home
      </Button>
    </div>
  )
}

export default NotFound