import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Utils
import { AlertSetter, setAlert } from '../actions/alert';
import { processError } from '../utils/errorHandling';
import { login } from '../actions/auth';

// Images
import { ReactComponent as ArcusFMLogo } from '../assets/arcusFmLogo.svg';
import ShowcaseBackgorundImg from '../assets/showcaseBackground.svg';

// Components
import Loading from '../components/Loading/ScreenLoading';
import Input from '../components/Input/Input';
import Button from '../components/Button/Button';

// Props
type LogonProps = {
  setAlert: AlertSetter['setAlert'];
  isAuthenticated: boolean;
  logonLocation: string;
  login: (emailAddress: string, password: string) => void;
};

const Logon: React.FC<LogonProps> = ({ setAlert, isAuthenticated, logonLocation, login }): JSX.Element | null => {
  // States
  const [loading, setLoading] = useState<{ fadeOut: boolean; loading: boolean }>({ fadeOut: false, loading: true });
  const [logonFormData, setLogonFormData] = useState<{ emailAddress: string; password: string }>({ emailAddress: '', password: '' });
  const [buttonLoading, setButtonLoading] = useState(false);

  // Form Handlers
  const handleLogonFormChange = (event: React.ChangeEvent<HTMLInputElement>) => setLogonFormData({ ...logonFormData, [event.target.name]: event.target.value });

  // Form Submit
  const handleLogonFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();

      setButtonLoading(true);

      // Validate form data
      if (!logonFormData.emailAddress) {
        setAlert('Login Error', 'Please enter an email address!', 'danger');
        setButtonLoading(false);
        return;
      }

      if (!logonFormData.password) {
        setAlert('Login Error', 'Please enter a password!', 'danger');
        setButtonLoading(false);
        return;
      }

      // Send request
      login(logonFormData.emailAddress, logonFormData.password);
      setButtonLoading(false);
    } catch (err: any) {
      processError(err);
      setButtonLoading(false);
    }
  };

  // On Mount
  useEffect(() => {
    // wait 1 second then set fadeOut to true then wait 1 second and set loading to false
    if (!isAuthenticated) {
      setTimeout(() => {
        setLoading({ fadeOut: true, loading: true });
        setTimeout(() => {
          setLoading({ fadeOut: true, loading: false });
        }, 1000);
      }, 1000);
    }
  }, [isAuthenticated]);

  if (isAuthenticated) return <Navigate to={logonLocation} />;
  // Render
  return (
    <>
      {loading.loading && <Loading fadeOut={loading.fadeOut} />}
      <div className='arcus-form-page'>
        <div className='arcus-form-page__showcase'>
          <img className='showcase-background' src={ShowcaseBackgorundImg} alt='Showcase Background' />

          <div className='showcase-content'>
            <h1>Capital Planning</h1>
            <ArcusFMLogo />
          </div>
        </div>

        <div className='arcus-form-page__form'>
          <form method='POST' onSubmit={handleLogonFormSubmit}>
            <h2 className='text-h1 colour-primary margin-bottom'>Sign In.</h2>
            <p className='margin-bottom text-default'>Please enter the your credentials to sign in.</p>

            <Input
              {...{
                label: 'Email Address',
                value: logonFormData.emailAddress,
                onChange: handleLogonFormChange,
                name: 'emailAddress',
                type: 'email',
                additionlInputProps: {
                  autoFocus: true
                }
              }}
            />
            <Input
              {...{
                label: 'Password',
                value: logonFormData.password,
                onChange: handleLogonFormChange,
                name: 'password',
                type: 'password',
                underneathItem: (
                  <Link className='input-link input-link__right text-default colour-secondary' to='/forgot-password'>
                    Forgot Password?
                  </Link>
                )
              }}
            />

            <div className='content-center'>
              <Button
                {...{
                  type: 'submit',
                  scale: 'full-width',
                  className: 'margin-bottom-small',
                  loading: buttonLoading,
                  colour: 'primary'
                }}
              >
                Log In.
              </Button>
              <p className='text-default-bold colour-default'>
                Don't have an account?{' '}
                <Link to='/request-account' className='colour-primary text-default-bold'>
                  Request Account
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  logonLocation: state.auth.logonLocation
});

const mapDispatchToProps = {
  setAlert,
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(Logon);
