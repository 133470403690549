// Alert types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const FADE_ALERT = 'FADE_ALERT';

// Auth types
export const USER_VALIDATED = 'USER_VALIDATED';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

// User types
export const UPDATE_USER = 'UPDATE_USER';

// Client types
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

// System types
export const SET_SECURITY_LIST = 'SET_SECURITY_LIST';

// Error
export const ERROR = 'ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// Filters
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// StoreAsset
export const SET_STORE = 'SET_STORE';
export const SET_ASSET = 'SET_ASSET';

// Database Connection
export const DB_CONNECTION = 'DB_CONNECTION';